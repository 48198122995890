.stack-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stack-container {
  margin: 20px 0;
  width: 80%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #7cff62c0;
  border-radius: 5px;
  animation: jackInTheBox 2s ease forwards;
}

.stack-content {
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  background-color: black;
  width: 20%;
  margin: 15px;
  padding: 5px;
  border-radius: 5px;
}

.stack-txt {
  font-size: 12px;
}

.stack-icon {
  padding-top: 10px;
}

.stack-p-styles {
  margin: 0;
}

@keyframes jackInTheBox {
  0% {
      -webkit-transform: scale(.1) rotate(30deg);
      -webkit-transform-origin: center bottom;
      opacity: 0;
      transform: scale(.1) rotate(30deg);
      transform-origin: center bottom
  }

  50% {
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }

  70% {
      -webkit-transform: rotate(3deg);
      transform: rotate(3deg)
  }

  to {
      -webkit-transform: scale(1);
      opacity: 1;
      transform: scale(1)
  }
}

/* Tablet */
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .stack-container {
    width: 50%;
  }
}

/* Desktop */
@media only screen and (min-width: 1000px) and (max-width: 1920px) {
  .stack-container {
    width: 40%;
    height: 350px;
  }

  .stack-content {
    display: flex;
    justify-content: center;
  }
}

/* Large Monitors */
@media only screen and (min-width: 1920px) and (max-width: 10000px) {
  .stack-container {
    width: 20%;
    height: 350px;
  }

  .stack-content {
    display: flex;
    justify-content: center;
  }
}