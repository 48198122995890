.project-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-card-h-styles {
  font-size: 30px;
  color: #7bff62;
  margin: 0;
  text-align: center;
  padding: 50px 0 10px 0;
}

.project-container {
  margin: 20px 0;
  width: 80%;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 5px;
  vertical-align: middle;
  transition-duration: 0.3s;
  transition-property: transform;
}

.project-container:hover {
  transform: scale(1.1);
}

.project-content {
  display: flex;
  flex-direction: column;
  color: black;
  align-items: center;
  width: 100%;
}

.project-img {
  height: 200px;
  width: 100%;
  background-repeat: round;
}

.project-card-content-h-styles {
  font-size: 15px;
  color: black;
  padding-left: 10px;
}

.project-card-p-styles {
  font-size: 10px;
  margin: 0 10px 5px 10px;
}

.project-card-btm-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.project-card-icon {
  background-color: black;
  color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  margin: 0 5px 5px 5px;
  width: 25px;
  font-size: 10px;
}

.project-card-visit-btn {
  background-color: white;
  border-radius: 10px;
  border: 2px solid black;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 5px 5px;
  width: 60px;
  font-size: 12px;
}

.project-card-visit-btn:hover {
  background-color: black;
  color: white;
}

/* Tablet */
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .project-container {
    width: 50%;
  }
}

/* Desktop */
@media only screen and (min-width: 1000px) and (max-width: 1920px) {
  .project-container {
    width: 40%;
  }

  .project-img {
    height: 400px;
  }

  .project-card-p-styles {
    font-size: 15px;
  }
}

/* Large Monitors */
@media only screen and (min-width: 1920px) and (max-width: 10000px) {
  .project-container {
    width: 20%;
  }

  .project-img {
    height: 500px;
  }

  .project-card-p-styles {
    font-size: 15px;
  }
}