.header-container {
  display: flex;
  justify-content: center;
  position: relative;
  top: 300px;
}

.highlightTxt {
  font-size: 50px;
  -webkit-text-stroke: 3px #39FF14;
}

.highlightTxt-active {
  color: #39FF14;
}