.contact-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.contact-icons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.top-item, h1 {
  color: white;
}

.top-item {
  display: flex;
  justify-content: center;
}

.bottom-item {
  width: 80%;
  font-weight: bolder;
  color: white;
}

.contact-button {
  color: black;
  border-radius: 5px;
  background-color: white;
  margin-top: 20px;
  border: 0;
  padding: 15px;
  font-size: 18px;
  width: 100%;
  max-width: 200px;
  align-self: center;
}

.contact-button:hover {
  background-color: #7cff62e0;
  color: white;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-form, label {
  width: 100%;
  margin: 10px 0 5px 0;
}

.contact-form input, .contact-form textarea {
  padding: 10px;
}

.github, .email, .linkedin {
  color: white;
}

.github:hover, .email:hover, .linkedin:hover {
  color: #7cff62e0;
}

/* Tablet */
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .bottom-item {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .contact-form {
    width: 50%;
  }

  .top-item, .contact-icons {
    width: 50%;
  }

  .contact-column {
    align-items: center;
  }
}

/* Desktop */
@media only screen and (min-width: 1000px) and (max-width: 1920px) {
  .bottom-item {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .contact-form {
    width: 50%;
  }

  .top-item, .contact-icons {
    width: 50%;
  }

  .contact-column {
    align-items: center;
  }
}

/* Large Monitors */
@media only screen and (min-width: 1920px) and (max-width: 10000px) {
  .bottom-item {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .contact-form {
    width: 30%;
  }

  .top-item, .contact-icons {
    width: 30%;
  }

  .contact-column {
    align-items: center;
  }
}