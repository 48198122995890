.ul-styles {
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  padding: unset;
  margin: unset;
}

.ul-styles li {
  padding-bottom: 5px;
  text-align: center;
}

.nav-icons {
  font-size: 30px;
  padding: 0 5px;
  color: black;
}

.sidenav-wrapper {
  opacity: 1;
}

.nav-text {
  margin: unset;
  color: black;
}

a {
  text-decoration: none;
}

.ul-styles li:hover {
  opacity: 0.5;
}

.ul-styles li:active {
  opacity: 1;
}

a:active {
  color: black;
}

.active {
  color: white;
}

.not-active {
  color: none;
}