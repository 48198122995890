.featured-h-styles {
  font-size: 30px;
  color: #7bff62;
  margin: 0;
  text-align: center;
  padding: 50px 0 10px 0;
}

.featured-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.featured-container {
  margin: 20px 0;
  width: 80%;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 5px;
}

.featured-content {
  display: flex;
  flex-direction: column;
  color: black;
  align-items: center;
  width: 100%;
}

.featured-content-h-styles {
  font-size: 15px;
  color: black;
  padding-left: 10px;
}

.featured-p-styles {
  font-size: 10px;
  margin: 0 10px 5px 10px;
}

.featured-btm-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.featured-icon {
  background-color: black;
  color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  margin: 0 5px 5px 5px;
  width: 25px;
  font-size: 10px;
}

.featured-btn {
  background-color: white;
  border-radius: 10px;
  border: 2px solid black;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 5px 5px;
  width: 60px;
  font-size: 12px;
}

.featured-btn:hover {
  background-color: black;
  color: white;
}

.prj-img {
  height: 242px;
  width: 100%;
  background-size: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .featured-h-animation {
    animation: flipInY 2s ease forwards;
  }

  .featured-animation {
    animation: fadeInLeft 2s ease forwards;
  }
}

/* Tablet */
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .featured-container {
    width: 50%;
  }
}

/* Desktop */
@media only screen and (min-width: 1000px) and (max-width: 1920px) {
  .featured-container {
    width: 40%;
  }

  .prj-img {
    height: 400px;
  }

  .featured-p-styles {
    font-size: 15px;
  }
}

/* Large Monitors */
@media only screen and (min-width: 1920px) and (max-width: 10000px) {
  .featured-container {
    width: 20%;
  }

  .prj-img {
    height: 500px;
  }

  .featured-p-styles {
    font-size: 15px;
  }
}