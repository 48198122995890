.footer-h-styles {
  color: #7bff62;
  font-size: 15px;
  margin: 10px;
  opacity: 0.7;
}

.footer-container {
  display: flex;
  justify-content: center;
  margin: 50px 0 30px 0;
}